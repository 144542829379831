<script setup>
  /* eslint-disable */

  import { registerLicense, loadCldr, L10n, setCulture } from '@syncfusion/ej2-base';
  import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";

  import * as modulesL from "@syncfusion/ej2-locale/src/pl.json";
  import * as modulesGregorianL from "cldr-data/main/pl/ca-gregorian.json";
  import * as modulesNumbersL from "cldr-data/main/pl/numbers.json";
  import * as modulesTimeZoneNamesL from "cldr-data/main/pl/timeZoneNames.json";
  import * as modulesTimeCurrenciesL from "cldr-data/main/pl/currencies.json";

  loadCldr(numberingSystems, modulesGregorianL, modulesNumbersL, modulesTimeZoneNamesL, modulesTimeCurrenciesL);
  L10n.load(modulesL);
  setCulture('pl');

  //Promise.all([modulesGregorian["/node_modules/cldr-data/main/" + llang + "/ca-gregorian.json"](),
  //modulesNumbers["/node_modules/cldr-data/main/" + llang + "/numbers.json"](),
  //modulesTimeZoneNames["/node_modules/cldr-data/main/" + llang + "/timeZoneNames.json"](),
  //modulesTimeCurrencies["/node_modules/cldr-data/main/" + llang + "/currencies.json"](),
  //modulesLAng["/node_modules/@syncfusion/ej2-locale/src/" + llangLocale + ".json"]()
  //]).then((values) => {
  //});

  registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Xd0xiWX9ddXJVRWdV');
  //registerLicense('ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9Rd01jXnpecHZTRmNU');

</script>
<template>
  <router-view />
</template>

<style lang="scss">
  // Import Main styles for this application
  @import 'styles/style';

  @import '@syncfusion/ej2-base/styles/bootstrap5.css';
  @import '@syncfusion/ej2-buttons/styles/bootstrap5.css';
  @import '@syncfusion/ej2-inputs/styles/bootstrap5.css';
  @import '@syncfusion/ej2-vue-dropdowns/styles/bootstrap5.css';
  @import '@syncfusion/ej2-popups/styles/bootstrap5.css';
  @import '@syncfusion/ej2-vue-calendars/styles/bootstrap5.css';
  @import '@syncfusion/ej2-navigations/styles/bootstrap5.css';
  @import '@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
  @import '@syncfusion/ej2-vue-grids/styles/bootstrap5.css';
  @import '@syncfusion/ej2-vue-notifications/styles/bootstrap5.css';

  @import "../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-layouts/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-vue-filemanager/styles/bootstrap5.css";
  
</style>
