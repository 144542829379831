
import { useStore } from 'vuex'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';


function GetAPIData() {
  //let apiUrl = " http://localhost:5083";
  //let apiUrl = " http://127.0.0.1:5083";
  let apiUrl = " https://api.jmp.d2u.pl";

  // eslint-disable-next-line
  if (typeof window.cfg.CoinfigApiUrl !== 'undefined') {
    // eslint-disable-next-line
    if (window.cfg.CoinfigApiUrl) {
      // eslint-disable-next-line
      apiUrl = window.cfg.CoinfigApiUrl;
      // eslint-disable-next-line
    }
  }

  var rdata = {
    Message: "",
    Data: null,
    Correct: true
  }

  var sss = useStore();
  //why useStore work only in created but not in events ?

  GetAPIData.prototype.ApiUrl = apiUrl;

  GetAPIData.prototype.Token = sss.state.jwt;

  GetAPIData.prototype.Request = async function (type, url, exData) {
    //let str = useStore();
    let sjwt = sss.state.jwt;

    if (type == "GET") {
      try {
        const config = { credentials: 'include' };
        if (sjwt !== '') config.headers = { 'Authorization': 'Bearer ' + sjwt };
        const response = await fetch(
          apiUrl + url, config
        );
        if (!response.ok) {
          rdata.Message = `HTTP error: ${response.status}`;
          rdata.Data = null;
          rdata.Correct = false
          return rdata;
        }
        const data = await response.json();
        return data;
      } catch (error) {
        rdata.Message = `Error ${error}`;
        rdata.Data = null;
        rdata.Correct = false
        return rdata;
      }
    }
    else {
      try {
        const config = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(exData)
        }
        //if (sjwt !== '') config.headers = { 'Authorization': 'Bearer ' + sjwt };
        if (sjwt !== '') config.headers['Authorization'] = 'Bearer ' + sjwt;
        const response = await fetch(
          apiUrl + url, config
        );
        if (!response.ok) {
          rdata.Message = `HTTP error: ${response.status}`;
          rdata.Data = null;
          rdata.Correct = false
          return rdata;
        }
        //const newCookie = response.headers.get('Set-Cookie');
        //console.log('Coockie:' + newCookie);

        const data = await response.json();

        return data;
      } catch (error) {
        rdata.Message = `Error ${error}`;
        rdata.Data = null;
        rdata.Correct = false
        return rdata;
      }
    }
  };

  GetAPIData.prototype.GetReport = function (url) {
    let sjwt = sss.state.jwt;
    let config = { credentials: 'include' };
    if (sjwt !== '') config.headers = { 'Authorization': 'Bearer ' + sjwt };
    fetch(apiUrl + url, config)
      .then(res => res.blob())
      .then(blob => {
        //var file = window.URL.createObjectURL(blob);
        //window.location.assign(file);
        window.open(URL.createObjectURL(blob));
      });
  }

  GetAPIData.prototype.GenrateDataManager = function (url, updateUrl, insertUrl, removeUrl) {
    let store = useStore();
    let dm = new DataManager({
      url: apiUrl + url,
      updateUrl: apiUrl + updateUrl,
      insertUrl: apiUrl + insertUrl,
      removeUrl: apiUrl + removeUrl,
      crossDomain: true,
      adaptor: new UrlAdaptor
    });
    dm.dataSource.headers = [{ 'Authorization': `Bearer ${store.state.jwt}` }];
    return dm;
  };

  GetAPIData.prototype.GenrateUrl = function (url) {
    return apiUrl + url;
  };

}

export { GetAPIData };
