import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
import './registerServiceWorker'


//import { GridPlugin } from "@syncfusion/ej2-vue-grids";

let cfgPath = './cfg.json';
//if (process.env.NODE_ENV === 'development') cfgPath = './public/cfg.json';

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)

/*app.use(GridPlugin);*/

app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)

fetch(cfgPath)
  .then((response) => response.json())
  .then((json) => {
    window.cfg = json;
    app.mount('#app')
  });
