<template>
  <CFooter>
    <div>
      <a href="https://fx2.pl/" target="_blank">
        FX2.pl Systemy Informatyczne
      </a>
      <span class="ms-1">
      &copy; {{ new Date().getFullYear() }} FX2.
      </span>
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Powered by</span>
      <a href="https://ddsoft.pl">DDSOFT</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
