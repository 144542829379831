import { ToastUtility } from "@syncfusion/ej2-vue-notifications";

function ShowMessage(message) {
  console.log(message);
  alert(message);
}

function ShowError(message) {
  console.log(message);
  let errorMessage = getString(message);
  let content = errorMessage;
  ToastUtility.show({
    title: 'Uwaga',
    cssClass: 'e-toast-danger e-toast-header-icon',
    icon: 'e-toast-error-icon',
    content: content,
    showProgressBar: true,
    timeOut: 20000,
    position: { X: 'Right', Y: 'Bottom' },
    showCloseButton: true,
  });

}
function ShowInfo(message) {
  console.log(message);
  ToastUtility.show({
    title: 'Info',
    cssClass: '',
    icon: '',
    content: message,
    showProgressBar: true,
    timeOut: 20000,
    position: { X: 'Right', Y: 'Bottom' },
    showCloseButton: true,
  });
}

function getString(data) {
  console.log('Get error');
  console.log(data);
  if (data == null) return "";
  if (typeof data === 'string' || data instanceof String) return data;
  if (typeof data === 'object' || data instanceof Object) {
    if (typeof data.error === 'object') {
      if (typeof data.error.message === 'string') return data.error.message;
      if (Array.isArray(data.error) && typeof data.error[0].error.responseText === 'string' && data.error[0].error.responseText !== '')
        return getErrorFromJson(data.error[0].error.responseText);
      if (Array.isArray(data.error) && typeof data.error[0].error.statusText === 'string' && data.error[0].error.statusText !== '')
        return data.error[0].error.statusText;
      if (typeof data.error.error.statusText === 'string' && data.error.error.statusText !== '')
        return data.error.error.statusText;
      return 'Nierozpoznany błąd!';
    }
  }
  return data.toString();
}

function getErrorFromJson(jsonString) {
  const json = JSON.parse(jsonString);
  return displayObjParams(json);
}

function displayObjParams(obj) {
  let result = "";
  Object.entries(obj).forEach(([key, value]) => {
    result += value;
    key;
  });

  return result;
}


export { ShowMessage, ShowError, ShowInfo };
