export const logoNegative = [
  '500 121',
  `
  <svg width="100%" height="100%" viewBox="0 0 500 121" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(2.16642,0,0,2.16642,-14.4084,-73.9804)">
        <g transform="matrix(0.983985,0,0,1.1452,-14.9018,-860.942)">
            <path d="M44.213,812.798C44.628,812.362 44.694,811.13 44.694,810.552L44.694,790.902L62.592,790.902L62.592,808.47C62.592,811.223 62.303,814.398 60.998,816.865C59.666,819.382 57.118,821.014 54.574,822.12C51.097,823.623 47.088,824.055 43.329,824.055C39.664,824.055 36.014,823.638 32.51,822.533C29.203,821.49 26.527,819.53 25.192,816.249C24.289,814.031 24.214,811.802 24.226,809.445L24.237,807.373L40.405,807.373L40.493,809.364C40.529,810.174 40.442,812.246 40.806,812.885C40.878,813.01 40.971,813.085 41.096,813.154C41.298,813.252 42.072,813.264 42.327,813.264C42.874,813.264 43.809,813.199 44.213,812.798ZM93.842,806.472L100.714,790.902L120.832,790.902L120.832,823.551L104.53,823.551L104.53,813.033L100.278,823.551L87.339,823.551L83.019,812.835L83.019,823.551L66.787,823.551L66.787,790.902L86.874,790.902L93.842,806.472ZM143.286,814.439L143.286,823.551L125.028,823.551L125.028,790.902L150.433,790.902C154.495,790.902 159.365,791.346 162.911,793.473C170.659,798.12 170.184,807.252 162.556,811.725C158.689,813.993 153.285,814.439 148.879,814.439L143.286,814.439ZM143.128,803.645L145.057,803.645C146.195,803.645 147.367,803.592 148.498,803.47C149.215,803.392 150.002,803.282 150.691,803.066C150.852,803.016 151.492,802.787 151.54,802.585C151.54,801.971 149.283,801.657 148.863,801.608C147.813,801.484 146.767,801.457 145.711,801.457L143.128,801.457L143.128,803.645Z" style="fill:white;"/>
        </g>
        <g transform="matrix(0.983985,0,0,1.1452,-14.9018,-860.943)">
            <path d="M46.777,792.985L60.509,792.985L60.509,808.47C60.509,811.72 60.055,814.193 59.157,815.891C58.259,817.589 56.462,819.028 53.747,820.208C51.031,821.383 47.562,821.973 43.329,821.973C38.848,821.973 35.595,821.323 33.136,820.547C26.215,818.364 26.292,812.871 26.309,809.456L38.412,809.456C38.514,811.756 38.199,813.927 40.086,814.976C40.597,815.259 41.357,815.347 42.327,815.347C43.855,815.347 44.981,814.983 45.703,814.253C46.416,813.517 46.777,812.284 46.777,810.553L46.777,792.985ZM68.87,792.985L85.524,792.985L93.856,811.601L102.071,792.985L118.749,792.985L118.749,821.469L106.613,821.469L106.613,802.324L98.874,821.469L88.745,821.469L80.936,802.098L80.936,821.469L68.87,821.469L68.87,792.985ZM141.045,805.728L145.057,805.728C148.216,805.728 150.425,805.437 151.71,804.846C152.981,804.256 153.622,803.5 153.622,802.585C153.622,801.689 153.067,800.927 151.956,800.31C150.858,799.687 148.772,799.375 145.711,799.375L141.045,799.375L141.045,805.728ZM127.111,792.985L150.433,792.985C155.508,792.985 159.312,793.743 161.84,795.259C168.319,799.145 167.672,806.311 161.502,809.929C158.743,811.547 154.535,812.356 148.879,812.356L141.203,812.356L141.203,821.469L127.111,821.469L127.111,792.985Z" style="fill:rgb(255,0,0);"/>
        </g>
    </g>
    <g transform="matrix(3.15245,0,0,3.15245,260.259,-239.802)">
        <g transform="matrix(16,0,0,16,11.3607,111.97)">
            <path d="M0.036,-0.233L0.177,-0.247C0.185,-0.199 0.202,-0.165 0.228,-0.143C0.254,-0.12 0.289,-0.109 0.333,-0.109C0.38,-0.109 0.415,-0.119 0.438,-0.139C0.462,-0.159 0.474,-0.182 0.474,-0.208C0.474,-0.225 0.469,-0.239 0.459,-0.251C0.449,-0.263 0.431,-0.273 0.407,-0.282C0.39,-0.288 0.351,-0.299 0.291,-0.313C0.214,-0.333 0.159,-0.356 0.128,-0.384C0.084,-0.424 0.062,-0.472 0.062,-0.528C0.062,-0.565 0.072,-0.599 0.093,-0.631C0.114,-0.662 0.143,-0.687 0.182,-0.703C0.221,-0.72 0.268,-0.728 0.323,-0.728C0.413,-0.728 0.481,-0.708 0.526,-0.669C0.572,-0.63 0.595,-0.577 0.598,-0.511L0.453,-0.505C0.447,-0.542 0.434,-0.568 0.413,-0.584C0.393,-0.6 0.362,-0.608 0.322,-0.608C0.28,-0.608 0.247,-0.6 0.223,-0.583C0.208,-0.571 0.2,-0.557 0.2,-0.538C0.2,-0.521 0.207,-0.507 0.222,-0.495C0.24,-0.479 0.284,-0.463 0.354,-0.447C0.425,-0.43 0.477,-0.413 0.51,-0.395C0.544,-0.378 0.571,-0.353 0.59,-0.323C0.609,-0.292 0.618,-0.254 0.618,-0.208C0.618,-0.167 0.607,-0.129 0.584,-0.093C0.561,-0.057 0.529,-0.031 0.487,-0.013C0.446,0.004 0.394,0.013 0.332,0.013C0.241,0.013 0.172,-0.008 0.123,-0.05C0.075,-0.092 0.046,-0.153 0.036,-0.233Z" style="fill:white;fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(16,0,0,16,22.0326,111.97)">
            <path d="M0.073,-0L0.073,-0.716L0.604,-0.716L0.604,-0.595L0.217,-0.595L0.217,-0.436L0.577,-0.436L0.577,-0.315L0.217,-0.315L0.217,-0.121L0.617,-0.121L0.617,-0L0.073,-0Z" style="fill:white;fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(16,0,0,16,32.7045,111.97)">
            <path d="M0.073,-0L0.073,-0.716L0.377,-0.716C0.454,-0.716 0.51,-0.709 0.544,-0.697C0.579,-0.684 0.607,-0.661 0.627,-0.628C0.648,-0.595 0.659,-0.557 0.659,-0.515C0.659,-0.461 0.643,-0.417 0.611,-0.382C0.58,-0.347 0.533,-0.325 0.47,-0.316C0.501,-0.298 0.527,-0.278 0.547,-0.256C0.567,-0.234 0.595,-0.195 0.629,-0.14L0.717,-0L0.544,-0L0.439,-0.156C0.402,-0.211 0.377,-0.247 0.363,-0.261C0.35,-0.275 0.335,-0.285 0.32,-0.291C0.305,-0.296 0.28,-0.299 0.247,-0.299L0.218,-0.299L0.218,-0L0.073,-0ZM0.218,-0.413L0.325,-0.413C0.394,-0.413 0.437,-0.416 0.455,-0.422C0.472,-0.428 0.485,-0.438 0.495,-0.452C0.505,-0.466 0.51,-0.484 0.51,-0.506C0.51,-0.53 0.503,-0.549 0.49,-0.564C0.478,-0.579 0.459,-0.588 0.436,-0.592C0.424,-0.594 0.389,-0.595 0.331,-0.595L0.218,-0.595L0.218,-0.413Z" style="fill:white;fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(16,0,0,16,43.9701,111.97)">
            <path d="M0.174,-0L0.003,-0.716L0.151,-0.716L0.259,-0.224L0.39,-0.716L0.562,-0.716L0.688,-0.216L0.797,-0.716L0.943,-0.716L0.769,-0L0.616,-0L0.473,-0.535L0.331,-0L0.174,-0Z" style="fill:white;fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(16,0,0,16,59.0717,111.97)">
            <rect x="0.068" y="-0.716" width="0.145" height="0.716" style="fill:white;fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(16,0,0,16,63.517,111.97)">
            <path d="M0.036,-0.233L0.177,-0.247C0.185,-0.199 0.202,-0.165 0.228,-0.143C0.254,-0.12 0.289,-0.109 0.333,-0.109C0.38,-0.109 0.415,-0.119 0.438,-0.139C0.462,-0.159 0.474,-0.182 0.474,-0.208C0.474,-0.225 0.469,-0.239 0.459,-0.251C0.449,-0.263 0.431,-0.273 0.407,-0.282C0.39,-0.288 0.351,-0.299 0.291,-0.313C0.214,-0.333 0.159,-0.356 0.128,-0.384C0.084,-0.424 0.062,-0.472 0.062,-0.528C0.062,-0.565 0.072,-0.599 0.093,-0.631C0.114,-0.662 0.143,-0.687 0.182,-0.703C0.221,-0.72 0.268,-0.728 0.323,-0.728C0.413,-0.728 0.481,-0.708 0.526,-0.669C0.572,-0.63 0.595,-0.577 0.598,-0.511L0.453,-0.505C0.447,-0.542 0.434,-0.568 0.413,-0.584C0.393,-0.6 0.362,-0.608 0.322,-0.608C0.28,-0.608 0.247,-0.6 0.223,-0.583C0.208,-0.571 0.2,-0.557 0.2,-0.538C0.2,-0.521 0.207,-0.507 0.222,-0.495C0.24,-0.479 0.284,-0.463 0.354,-0.447C0.425,-0.43 0.477,-0.413 0.51,-0.395C0.544,-0.378 0.571,-0.353 0.59,-0.323C0.609,-0.292 0.618,-0.254 0.618,-0.208C0.618,-0.167 0.607,-0.129 0.584,-0.093C0.561,-0.057 0.529,-0.031 0.487,-0.013C0.446,0.004 0.394,0.013 0.332,0.013C0.241,0.013 0.172,-0.008 0.123,-0.05C0.075,-0.092 0.046,-0.153 0.036,-0.233Z" style="fill:white;fill-rule:nonzero;"/>
        </g>
    </g>
</svg>
`,
]
