let Roles = [
  { name: "Admin", id: "f39b245f-73bf-4e78-8a93-a5ecc284000f" },
  { name: "Manager", id: "c4fa3a2a-2d4c-4623-96ba-de5389dd116f" },
  { name: "Serwis", id: "a4ac85fa-1840-4f47-899a-69e87f57f923" },
  { name: "Pracownik", id: "41860b99-25bc-4ca3-af42-b4d9c16a0330" }
]

let DateFormat = {
  type: 'date', format: 'dd.MM.yyyy'
}

let GridConfig = {
  PageSettings: { pageSize: 200 },
  AllowPaging: true,
  EnableStickyHeader: true,
  Width: "100%",
  Height: "100%",
}

let GridFilterOptionsMenuData = {
  type: 'Menu',
  operators: {
    stringOperator: [
      { value: 'contains', text: 'Zawiera' },
      { value: 'startsWith', text: 'Zaczyna sie od' },
      { value: 'endsWith', text: 'Kończy się na' },
      { value: 'equal', text: 'Równa się' }
    ],
    dateOperator: [
      { value: 'greaterThanOrEqual', text: 'Większa bądź równa niż' },
      { value: 'lessThanOrEqual', text: 'Mniejsza bądż równa niż' },
    ],
  },
}

function GridFilterOptionsMenu() {
  return JSON.parse(JSON.stringify(GridFilterOptionsMenuData));
}

let GridIds = [
  "Grid", "GridPMAll", "GridFirstAll", "GridFirstAll", "GridPMW", "GridRB", "GridRP", "GridRW", "GridRWA2", "GridRWA", "GridZW", "gHistoria", "gFaktury", "gUslugi", "GridS"
]

let ZleceniaTypyData = [{ value: 'M', text: "Serwis mobilny" }, { value: 'S', text: "Zlecenie warsztatowe" }]

let CeleTypyData = [{ value: 'Gwarancja', text: "Gwarancja" }, { value: 'Płatna', text: "Płatna" }, { value: 'Przegląd okresowy', text: "Przegląd okresowy" }]

let TypyDokumentKsiegowyData = [{ value: 'RW', text: "RW" }, { value: 'WZ', text: "WZ" }, { value: 'GW', text: "GW" }]

let TNTypyData = [{ value: 'T', text: "Tak" }, { value: 'N', text: "Nie" }]
function ZleceniaTypy() {
  return JSON.parse(JSON.stringify(ZleceniaTypyData));
}
function CeleTypy() {
  return JSON.parse(JSON.stringify(CeleTypyData));
}

function TNTypy() {
  return JSON.parse(JSON.stringify(TNTypyData));
}

function TypyDokumentKsiegowy() {
  return JSON.parse(JSON.stringify(TypyDokumentKsiegowyData));
}

function ResetGridsData() {
  for (let i = 0; i < GridIds.length; i++) {
    let gname = "grid" + GridIds[i];
    console.log("Czyszczę " + gname);
    let el = document.getElementById(GridIds[i]);
    let [ej2] = el?.ej2_instances || [];
    if (ej2) {
      ej2.enablePersistence = false;
      window.localStorage.setItem(gname, "");
      ej2.destroy();
      console.log("Czyszczę ex " + gname);
    } else {
      window.localStorage.setItem(gname, "");
    }
  }
}

export { Roles, DateFormat, GridConfig, GridFilterOptionsMenu, ResetGridsData, ZleceniaTypy, CeleTypy, TNTypy, TypyDokumentKsiegowy };
