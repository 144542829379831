<template>
  <CDropdown>
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Ustawienia - {{ login }}
      </CDropdownHeader>
      <CDropdownItem @click="this.$router.push('/jmp/changePassword');">
        <CIcon icon="cil-shield-alt" /> Zmień hasło
      </CDropdownItem>
      <CDropdownItem @click="resetAllGrid">
        <CIcon icon="cil-code" /> Reset ustawień tabel
      </CDropdownItem>
      <CDropdownItem @click="logout"> <CIcon icon="cil-lock-locked" /> Wyloguj </CDropdownItem>
    </CDropdownMenu>

    <!--<CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <CDropdownItem>
        <CIcon icon="cil-bell" /> Updates
        <CBadge color="info" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-envelope-open" /> Messages
        <CBadge color="success" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-task" /> Tasks
        <CBadge color="danger" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-comment-square" /> Comments
        <CBadge color="warning" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Ustawienia
      </CDropdownHeader>
      <CDropdownItem> <CIcon icon="cil-user" /> Profile </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-settings" /> Settings </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-dollar" /> Payments
        <CBadge color="secondary" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-file" /> Projects
        <CBadge color="primary" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem>
        <CIcon icon="cil-shield-alt" /> Lock Account
      </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-lock-locked" /> Wyloguj </CDropdownItem>
    </CDropdownMenu>-->
  </CDropdown>
</template>

te
ri

<script>
  import { useStore } from 'vuex'
  import { computed } from 'vue'
  import avatar from '@/assets/images/avatars/user.jpg'
  import { GetAPIData } from "./../tools/getData";
  import { ShowError } from "./../tools/message";
  import { ResetGridsData } from "./../tools/params";

  export default {
    name: 'AppHeaderDropdownAccnt',
    setup() {
      const store = useStore()
      return {
        avatar: avatar,
        itemsCount: 42,
        apiData: null,
        login: computed(() => store.state.login),
      }
    },
    created() {
      this.apiData = new GetAPIData();
    },
    methods: {
      logout: function () {
        console.log('logout');
        this.apiData.Request("POST", "/api/Auth/logOff", {}).then((data) => {
          if (data.Correct === false) {
            console.log('Error logout');
            ShowError(data.Message);
          } else {
            this.$store.dispatch('loginSystem', { token: '', role: '', login: '' })
            this.$router.push('/');
          }
        });
      },
      resetAllGrid: function () {
        ResetGridsData();
        location.reload();
      },
    },
  }
</script>
