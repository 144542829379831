import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    jwt: '',
    login: '',
    role: '',
    clipboard: '',
    name: '',
    surname: '',
    uGuid: ''
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    updateJwtToken(state, token) {
      state.jwt = token
    },
    updateLogin(state, login) {
      state.login = login
    },
    updateRole(state, role) {
      state.role = role
    },
    updateName(state, name) {
      state.name = name
    },
    updateSurname(state, surname) {
      state.surname = surname
    },
    updateUGuid(state, uGuid) {
      state.uGuid = uGuid
    },
    updateClipboard(state, clipboard) {
      state.clipboard = clipboard;
    }
  },
  actions: {
    loginSystem(context, payload) {
      context.commit('updateLogin', payload.login);
      context.commit('updateRole', payload.role);
      context.commit('updateJwtToken', payload.token);
      if (Object.prototype.hasOwnProperty.call(payload, 'name')) {
        context.commit('updateName', payload.name);
      }
      if (Object.prototype.hasOwnProperty.call(payload, 'surname')) {
        context.commit('updateSurname', payload.surname);
      }
      if (Object.prototype.hasOwnProperty.call(payload, 'uGuid')) {
        context.commit('updateUGuid', payload.uGuid);
      }
    }
  },
  modules: {},
})
