<template>
  <CHeader position="sticky" class="mb-4" :class="currentPath">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CIcon :icon="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="/#/start"> Start </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#/jmp/users">Użytkownicy</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#/dev/working">Parametry</CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <!--<CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-bell" size="lg" />
          </CNavLink>
        </CNavItem>-->
        <CNavItem>
          <CNavLink href="/#/start">
            <CIcon class="mx-2" icon="cil-list" size="lg" />
          </CNavLink>
        </CNavItem>
        <!--<CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-envelope-open" size="lg" />
          </CNavLink>
        </CNavItem>-->
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer :class="currentPath" fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
  import AppBreadcrumb from './AppBreadcrumb'
  import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
  import { logo } from '@/assets/brand/logo'

  import nav from '@/_nav.js'
  import Admin_nav from '@/Admin_nav.js'
  import Manager_nav from '@/Manager_nav.js'
  import Serwis_nav from '@/Serwis_nav.js'

  //import { useStore } from 'vuex'
  //import { useRoute } from 'vue-router'
  //import { computed } from 'vue'


  export default {
    name: 'AppHeader',
    components: {
      AppBreadcrumb,
      AppHeaderDropdownAccnt,
    },

    computed: {
      // Używamy właściwości obliczanej, aby uzyskać aktualną ścieżkę
      currentPath() {
        console.log('role navs');
        console.log(this.roleNavs);

        //odnajdź odpowiedni obiekt z kolekcji roleNavs ktorego .to jest równa this.$route.path
        //zwróć jego .color

         const matchingNav = this.roleNavs.find(navItem => navItem.to === this.$route.path);

 // If a matching object is found, return its .color property
  if (matchingNav) {
    return matchingNav.color;
  }


        return this.$route.path;
      },
      roleNavs() {
        console.log('Odczytuje');
        let start = this.$store.state.role;
        switch (start) {
          case "Admin":
            return Admin_nav;
          case "Manager":
            return Manager_nav;
          case "Serwis":
            return Serwis_nav;
        }
        return nav;
      },
    },

    setup() {
      //const route = useRoute()
      //const store = useStore()


      //const roleNavs = computed(() => {
      //  console.log('Odczytuje');
      //  let start = store.state.role;
      //  switch (start) {
      //    case "Admin":
      //      return Admin_nav;
      //    case "Manager":
      //      return Manager_nav;
      //    case "Serwis":
      //      return Serwis_nav;
      //  }
      //  return nav;
      //});

      //console.log(roleNavs);

      return {
        logo,
      }
    },



  }
</script>
